@import-normalize;

@font-face {
  font-family: 'Visuelt';
  src: local('Visuelt'), url(./font/VisueltPro-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Visuelt';
  font-weight: 400;
  src: local('Visuelt'), url(./font/VisueltPro-Bold.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: Visuelt, 'open sans', -apple-system, blinkmacsystemfont, 'segoe ui',
    'roboto', 'oxygen', 'ubuntu', 'cantarell', 'fira sans', 'droid sans',
    'helvetica neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}