@import '../../shared.scss';
@import '~antd/dist/antd.css';

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(../../font/Roboto-Regular.ttf) ‚ format('truetype');
  }
  
  @font-face {
    font-family: 'Roboto';
    font-weight: 600;
    src: local('Roboto'), url(../../font/Roboto-Bold.ttf), format('truetype');
  }

.lawyerMain{
    background-color: white;
    width: 100%;
    padding-top: 25px;
    margin: -25px 0 0 0;
    font-family: Roboto !important;
  }
  
  .lawyerContent{
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    font-size: 16px;
  }

  p{
      font-weight: 300;
  }
  
  .lawyerForm{
    width: 50%;
    margin: 0 auto;
    box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.47);
  }
  
  .steps {
    width: 90%;
    max-width: 1200px;
    margin: 15px auto;
    padding: 10px
  }
  
  .lawyerContent .ant-typography{
    color: $header-text-color !important;
  }

  .lawyerMain
  h1.ant-typography,
  h2.ant-typography,
  h3.ant-typography,
  h4.ant-typography{
      font-family: Roboto;
      font-weight: normal;
  }

  

  
  .lawyerMain h1{
  font-size: 50px;
  }

  .lawyerMain h2{
      font-size: 44px;
  }

  .lawyerMain h4{
      font-size: 32px !important;
  }

  .lawyerForm h1{
    font-size: 40px;
    }
  
    .lawyerForm h2{
        font-size: 44px;
    }
  
    .lawyerForm h4{
        font-size: 16px !important;
    }
  
  .lawyer-form{
    padding: 10px;
  }
  
  .lawyerTitle {
    color: $header-text-color !important;
  }
  
  .lawyerContent .ant-row{
  margin-top: 250px;  
  margin-bottom: 250px;  
  }
  
  h4.ant-typography{
    color: $search-text-color;
    margin-bottom: 20px;
  }
  
  .lawyer-box{
    background-color: #FEFDF8;
    border-radius: 5%;
  }
  
  @media only screen and (max-device-width: 576px) {
    .lawyerContent{
      width: 90%;
      margin: 0 auto;
    }
  
    .lawyerForm{
      width: 90%;
      margin: 0 auto;
      box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.47);
    }
  
    .steps {
      width: 90%;
      margin: 15px auto;
      padding: 10px
    }
  }