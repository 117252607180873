@import "../../shared.scss";
@import "~antd/dist/antd.css";


h1,
h2,
h3,
h4 {
    font-family: "roboto slab", serif;
    font-weight: 400;
}

.header-row.subpage {
    background-color: white;
    color: $header-text-color !important;
    border-color: lightgray;
    border-radius: 15px;
    border-style: solid;
    border-width: 1px;
    width: 100% !important;
    margin: 0 auto;
    padding-bottom: 0;
    margin-bottom: 10px;
}

div.header-underheader {
    font-family: Visuelt, 'open sans', sans-serif;
    font-size: 1.2em;
    padding-bottom: 15px;

}

.form-left {
    text-align: left !important;
}



.main-results {
    background-color: white;
    border-color: lightgray;
    border-radius: 26px;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding-bottom: 20px;
    overscroll-behavior: none;
}

.hero-row {
    height: 450px;
    background-color: $primary-color;
    padding-top: 20px;
    overscroll-behavior: none;
}

.form-inline-input {
    display: inline-block;
    width: 100%;
}

.ant-form-item-label {
    display: inline;
    overflow: visible;
    white-space: nowrap;
    vertical-align: middle;
}