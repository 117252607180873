$primary-color: #009de0;
$secondary-color: #caeae7;
$tertiary-color: #e7f5f4;
$tertiary-text: #b8e4f6;
$add-button-color: #009b8e;
$logo-orange: #EF8633;
$max-content-width: 1300px;
$search-text-color: #626262;
$text-color: #414141;
$header-text-color: #252a35;
$background-color: #fafbfc;